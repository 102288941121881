import { Meta, Layout, Section } from "~/ui";
import { Routes } from "~/models";

import messageSentImg from "~/assets/images/contact.png";

export default function MessageSent(): JSX.Element {
  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row mb-4">
          <div className="col-12 col-lg-8 d-flex flex-column justify-content-center mb-4">
            <h1 className="d-flex flex-column text-primary">
              <span className="display-2">Thank you.</span>
              <span>Your message has been sent.</span>
            </h1>
          </div>
          <div className="col-12 col-lg-4 text-center mb-4">
            <img
              src={messageSentImg}
              alt="People talking"
              className="img-fluid mt-lg-n5"
              width={364}
              height={590}
            />
          </div>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return (
    <Meta
      title="Thank you"
      description="Your message has been sent."
      url={Routes.MessageSent}
    />
  );
};
